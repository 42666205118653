/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */

// var streamplayer, firstInitLayout = true;

var Layout = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var
        $templateBackdrop,
        $templateHeaderMain,
        $templateScrollTop,
        headerSwithed = false,
        animObj = {},
        debounceScroll = false,
        firstInit = true;

    var allslides = 0;
    var actslide = 0;
    var slideTimer;
    var deferredFooter;

    /* ----- Init Backdrop on Ready ----------------------------------------------------------------------------------- */
    function Init() {
        var
            $details = $('div.details'),
            $pages = $('div.main:not(.details, .system)');

        buildFooter();

        $(window).on('resize', function () {
            clearTimeout(deferredFooter);
            deferredFooter = setTimeout(function(){
                buildFooter();
            }, 500);
        });

        /* Get template tags */
        $templateBackdrop = $('template#backdrop').remove().contents();
        $templateHeaderMain = $('template#mainModuleHeader').remove().contents();
        $templateScrollTop = $('template#mainScrollTop').remove().contents();
        /* Build Header of Main Layouts */
        $.each($pages, _eachInitPages);
        var navs = AppData.navigation.filter(function(el){
            return el.group !== null && el.group.indexOf(AppData.profile.group) > -1;
        });
        $.each(navs, _eachInitNavigation);
        /* Build Header of Details Layouts */
        $.each($details, _eachInitDetails);
        /* Restore Last State */
        // $.each(localState.layout, _eachInitLayout);
        /* Bind Events for Scroll To Top */
        var articles = document.querySelectorAll('div.main:not(#profile):not(#surveys-details):not(#game) article');
        for (var i = 0; i < articles.length; i++) {
            articles[i].addEventListener('scroll', evtScroll, {
                passive: true
            });
        }
        $(document).on('click', 'div.main .scroll-top', evtClickSctollTop);
        switchHeader();

        if(AppData.modulsettings.broadcastThumbnail !== undefined && AppData.modulsettings.broadcastThumbnail != "") {
            Broadcast.setBroadcastPoster();
        }

        document.title = AppData.modulsettings.browsertitle[localState.lang];
        setFavicon();

        if(AppData.settings.homeScreen === "floating") {
            $("div.main").not("#hamburger").not("#modal").not(".system").css("background-color", "transparent");
            $("#landing .landing_elements").find(".trueconf").css("z-index", "1000");
        }
        delete Layout.Init;
        $details = articles = null;
    }

    function _eachInitPages(i, el) {
        var
            $cloneHeader = $templateHeaderMain.clone(),
            $cloneScrollTop = $templateScrollTop.clone();

        if ($('#' + el.id + ' header').length === 0) {
            var module = AppData.modules.find(function (e) {
                return e.id === el.id;
            });
            if (module && $('#' + el.id + ' header').length === 0) {
                $cloneHeader.find('.data-back').text("Data-back");
                $cloneHeader.prependTo('#' + el.id);
                $cloneScrollTop.appendTo('#' + el.id);
                $cloneHeader = $cloneScrollTop = null;
            }
        }

        if (AppData.settings.homeScreen == "floating") {
            $('#' + el.id + ' article .container').wrap('<div class="outer"/>');
        }

        if ($('#' + el.id + ' article .outer .closebutton').length === 0) {
            let closebutton = $("<div/>").addClass("closebutton color-primary");
            closebutton.append('<i class="fas fa-times"></i>');
            $('#' + el.id + ' article .outer').append(closebutton);
        }

        if ($('#' + el.id + ' article .outer .backbutton').length === 0) {
            let backbutton = $("<div/>").addClass("backbutton color-primary close-main");
            backbutton.append('<i class="fas fa-chevron-left"></i>');
            $('#' + el.id + ' article .outer').append(backbutton);
        }
    }

    /* Each Function */
    function _eachInitNavigation(i, el) {
        if (el.visible == true && $('#' + el.module + ' header').length === 1) {
            var icon;
            if(el.icon != undefined && el.icon != "") {
                icon = el.icon;
            } else {
                var module = AppData.modules.find(function (e) {
                    return e.id === el.module;
                });
                icon = module.icon;
            }

            var selector = el.module;
            if (el.target != "" && el.target != undefined) {
                selector = selector+"-details";
            }

            if(localState.layoutActiveMenu === el.id) {
                $('#' + selector + ' header').find('h3 .data-icon').removeClass().addClass("data-icon " + icon);
                $('#' + selector + ' header').find('h3 .data-label').html(el.name[localState.lang]);
            }
        }
    }

    /* Each Function */
    function _eachInitDetails(i, el) {

        var
            $cloneHeader = $templateHeaderMain.clone(),
            $cloneScrollTop = $templateScrollTop.clone(),
            $detailsOpener = $('#' + el.id.replace('-details', ''));

        if ($('#' + el.id + '-details header').length === 0) {
            $cloneHeader.find('.data-icon').addClass($detailsOpener.find('header .data-icon').attr('class'));
            $cloneHeader.prependTo('#' + el.id);
            $cloneScrollTop.appendTo('#' + el.id);
            $cloneHeader = null;
        }

        if (AppData.settings.homeScreen == "floating") {
            $('#' + el.id + ' article .container').wrap('<div class="outer"/>');
        }

        if ($('#' + el.id + ' article .outer .closebutton').length === 0) {
            let closebutton = $("<div/>").addClass("closebutton color-primary");
            closebutton.append('<i class="fas fa-times"></i>');
            $('#' + el.id + ' article .outer').append(closebutton);
        }

        if ($('#' + el.id + ' article .outer .backbutton').length === 0) {
            let backbutton = $("<div/>").addClass("backbutton color-primary close-main");
            backbutton.append('<i class="fas fa-chevron-left"></i>');
            $('#' + el.id + ' article .outer').append(backbutton);
        }
    }

    /* Each Function */
    function _eachInitLayout(i, el) {
        show(el);
    }

    /* ----- Event: Cick on Scoll Top ------------------------------------------------------------------------------- */
    function evtClickSctollTop() {
        var $main = $(this).closest('div.main').find('article');
        $main.animate({
            scrollTop: 0
        }, 300, 'linear');
        $main = null;
    }

    /* ----- Event: Scroll on Main Article -------------------------------------------------------------------------- */
    function evtScroll() {
        animObj.self = this;
        animObj.scrollX = animObj.self.scrollTop;
        animObj.height = animObj.self.offsetHeight;
        if (!debounceScroll) {
            debounceScroll = true;
            requestAnimationFrame(processScroll);
        }
    }

    function processScroll() {
        debounceScroll = false;
        if (animObj.scrollX > animObj.height * 2 / 3) {
            $(animObj.self).find('.container').css('marginBottom', '45pt');
            $(animObj.self).next('.scroll-top-wrapper').removeClass('hidden fadeOutDown').addClass('fadeInUp');
        } else {
            $(animObj.self).find('.container').css('marginBottom', '');
            $(animObj.self).next('.scroll-top-wrapper').removeClass('fadeInUp').addClass('hidden fadeOutDown');
        }
    }

    /* ----- Show Layout ---------------------------------------------------------------------------------------------- */
    function show(id, immediate) {
        var
            immediate = immediate || false,
            $el = $('#' + id).not('#landing, #game'),
            $isSystem = $el.hasClass('system'),
            $backdrop = $templateBackdrop.clone();

        if (id != "gdpr") {
            Navigation.setAllowCloseMenu();
        }

        let menuitem = AppData.navigation.find(function(e) {
            return e.id == localState.layoutActiveMenu;
        });

        let open_in_main = false;

        if (menuitem) {
            if (menuitem.open_in_main) {
                immediate = true;
                open_in_main = true;
            }
        }

        if(id == "camerastream") {
            Camerastream.startStream();
            return;
        }

        if(id == "trueconf") {
            /*if (trueconf_opened || trueconf_translator_opened) {
                $("#default_nav").find("[data-target='trueconf']").css("border", '');
                closeTrueConfCall();
                return;
            }*/
        }

        if (id == "speaker-presentation") {
            if (localState.presentation) {
                if ($(".speaker_presentation").is(":visible")) {
                    SpeakerPresentation.hide();
                    $("#default_nav").find("[data-target='speaker-presentation']").css("border", '');
                } else {
                    SpeakerPresentation.show();
                    if($(".speaker_presentation").is(":visible")) {
                        $("#default_nav").find("[data-target='speaker-presentation']").css("border", '2px solid ' + AppData.settings.colorIconBorder);
                    }
                }
            }
            return;
        }

        Layout.hide('hamburger');
        /* Blur all Focused element */
        if ("activeElement" in document) {
            document.activeElement.blur();
        }

        if ($el.length && !$el.is(':visible')) {
            if (!open_in_main) {
                /* localState declared in /src/shared/common.js */
                localState.layout = localState.layout.filter(function (el) {
                    return el !== id;
                });
                localState.layout.push(id);
            }
            $('.bubbles1, .bubbles2').hide();

            var fadeOutClass;
            var fadeInClass;

            if ($el.hasClass("reverse")) {
                fadeOutClass = "fadeOut" + (AppData.settings.homeScreen === "classic" ? "Left" : "");
                fadeInClass = "fadeIn" + (AppData.settings.homeScreen === "classic" ? "Left" : "");
            } else {
                fadeOutClass = "fadeOut" + (AppData.settings.homeScreen === "classic" ? "Right" : "");
                fadeInClass = "fadeIn" + (AppData.settings.homeScreen === "classic" ? "Right" : "");
            }

            // if (!immediate && (AppData.settings.homeScreen === "classic" || id == "hamburger")) {
            if (!immediate) {
                $el
                    .show().css("display", "flex")
                    .removeClass(fadeOutClass+' fadeOut')
                    .addClass($isSystem ? 'animated faster fadeIn' : 'animated faster '+fadeInClass);
                if (!open_in_main) {
                    $el.appendTo('.bodycontent');
                }
            } else {
                if (!open_in_main) {
                    $el.appendTo('.bodycontent');
                }
                $el.show().css("display", "flex");
            }

            if (!$isSystem && (AppData.settings.homeScreen === "classic" || id == "hamburger") && !open_in_main) {
                $backdrop
                    .show()
                    .addClass('animated faster fadeIn')
                    .insertBefore($el);
            }

            if(id != "" && AppData.settings.homeScreen === "floating" && !open_in_main) { //
                //$("#default_nav .wrapper").hide();
                //$("#default_nav").hide();
            }

            if(localState.layout.length > 1 && id != "hamburger") {
                setTimeout(function() {
                    if(AppData.settings.homeScreen === "floating") {
                        $("#" + localState.layout[localState.layout.length - 2]).hide();
                    }
                }, 300);
            }

            if (localState.layout.length > 0 && id != "hamburger" && AppData.settings.homeScreen == "classic" && !open_in_main) {
                Broadcast.minifyPlayer();
            }

            if (localState.layout.length > 1 && !open_in_main && AppData.settings.homeScreen !== "classic") {
                $(".backbutton").show();
            } else {
                $(".backbutton").hide();
            }
        }

        let selector_class = id.charAt(0).toUpperCase() + id.slice(1);
        if (window[selector_class] != undefined) {
            if (typeof window[selector_class].shown == "function") {
                window[selector_class].shown();
            }
        }

        if (localState.layout.length > 0 && AppData.modulsettings.cdown_showonclient) {
            $("#landing .countdown_bc").addClass("hidden");
        }

        if (localState.layout.length > 0) {
            $(".videostream").appendTo($(".videostream").parent());
        }

        if(AppData.modulsettings.show_quickmenu && id !== "hamburger") {
            $("#minimal_nav").removeClass("topAnimationUp").addClass("topAnimationDown");
            if(localState.layout.length > 0 && $(window).width() < 780) {
                //$("#minimal_nav").find(".container").css('background-color', '#ffffff');
            }
        }

        if(AppData.modulsettings.show_quickmenu && id === "hamburger") {
           $("#minimal_nav").css("z-index", "");
        }

        $el = $isSystem = $backdrop = null;
    }

    /* ----- Hide Layout ---------------------------------------------------------------------------------------------- */
    function hide(id, immediate) {
        var
            immediate = immediate || false,
            $el = $('#' + id).not('#landing, #game'),
            $isSystem = $el.hasClass('system'),
            $backdrop = $el.prev('div.main.backdrop'),
            $scrolltop = $el.find('.scroll-top-wrapper');

        if(id == "hamburger") {
            /*var myPlayer = videojs('#my-video');
            if(!myPlayer.paused())
                $(".videostream").show();*/
        }

        if(id == "camerastream") {
            Camerastream.stopStream();
            return;
        }

        /* Blur all Focused element */
        if ("activeElement" in document) {
            document.activeElement.blur();
        }
        if ($el.length && $el.is(':visible')) {
            /* localState declared in /src/shared/common.js */
            localState.layout = localState.layout.filter(function (el) {
                return el !== id;
            });
            $scrolltop.removeClass('fadeInUp').addClass('hidden');
            $el.trigger('hide');
            // if (!immediate && (AppData.settings.homeScreen === "classic"|| id == "hamburger")) {
            if (!immediate) {

                var fadeOutClass;
                var fadeInClass;

                if ($el.hasClass("reverse")) {
                    fadeOutClass = "fadeOutLeft";
                    fadeInClass = "fadeInLeft";
                } else {
                    fadeOutClass = "fadeOutRight";
                    fadeInClass = "fadeInRight";
                }

                if (AppData.settings.homeScreen === "floating") {
                    fadeOutClass = "fadeOut";
                    fadeInClass = "fadeIn";
                }

                $el
                    .on('animationend animationcancel', function (e) {
                        var target = e.target || e.originalEvent.target;
                        if (target === this) {
                            $el
                                .off('animationend animationcancel')
                                .removeClass('animated faster fadeOut '+fadeOutClass).hide();
                            if (AppData.settings.homeScreen === "classic" || id == "hamburger") {
                                $backdrop.remove();
                            }
                            if ($('div.main:visible').length === 1) {
                                if (AppData.modulsettings.show_quickmenu) {
                                    $("#minimal_nav").removeClass("topAnimationDown").addClass("topAnimationUp");
                                }

                                if (AppData.settings.bubble == "hidden") {
                                    $('.bubbles1, .bubbles2').hide();
                                } else {
                                    $('.bubbles1, .bubbles2').show();
                                }
                            }
                            $el = $isSystem = $backdrop = $scrolltop = null;
                        }
                        target = null;
                    })
                    .removeClass('faster fadeIn '+fadeInClass)
                    .addClass($isSystem ? 'animated faster fadeOut' : 'animated faster '+fadeOutClass);
                if (!$isSystem && (AppData.settings.homeScreen === "classic" || id == "hamburger")) {
                    $backdrop
                        .removeClass('fadeIn')
                        .addClass('animated faster fadeOut');
                }
            } else {
                if(AppData.settings.homeScreen === "classic" || id == "hamburger") {
                    $backdrop.remove();
                }
                $el.hide();
                $el = $isSystem = $backdrop = $scrolltop = null;
            }
            if(localState.layout.length === 0 && AppData.settings.homeScreen == "floating") {
                $("#default_nav").show();
            }

            if(localState.layout.length === 0 && AppData.modulsettings.show_quickmenu && $(window).width() < 780) {
                Navigation.setQuickMenuColors();
            }
        }

        Navigation.hideMenu();

        if (localState.layout.length > 1) {
            $(".backbutton").show();
        } else {
            $(".backbutton").hide();
        }

        // $("main#" + id + " .header-secondary").appendTo($("main#" + id)).css("position", "absolute").css("z-index", "1000").css("top", "0");

        if(localState.layout.length === 0) {
            let mains_in_landing = $("#landing header .landing_elements .main");

            if (mains_in_landing.length > 0) {
                $(".videostream").insertBefore(mains_in_landing.first());
            } else {
                if ($("#landing header .landing_elements").has($(".videostream")).length == 0) {
                    $(".videostream").appendTo($("#landing header .landing_elements"));
                }
            }

            if (AppData.modulsettings.cdown_showonclient) {
                $("#landing .countdown_bc").removeClass("hidden");
            }

            Broadcast.restorePlayer();
            Trueconf.showTranslator();
        }

        setMainSizes();

        if(AppData.modulsettings.show_quickmenu && localState.layout.length === 0) {
            $("#minimal_nav").removeClass("topAnimationDown").addClass("topAnimationUp");
        }

        if(AppData.modulsettings.show_quickmenu && id === "hamburger") {
            $("#minimal_nav").css("z-index", "2");
        }
    }

    /* ----- Swap Layout ---------------------------------------------------------------------------------------------- */
    function swap(id) {
        if (!AppData.modulsettings.showgdpr || AppData.profile.gdpr == true) {
            var $el = $('#' + id);
            $el.siblings('.bodycontent > div.main:visible').not('.backdrop').each(_eachSwapHide);
            show(id);
            if(id !== "hamburger") {
                $("#minimal_nav").removeClass("topAnimationUp").addClass("topAnimationDown");
            }
            $el = null;
        }
    }

    /* ----- Swap Layout ---------------------------------------------------------------------------------------------- */
    function hideAll() {
        localState.layout = [];
        $('.bodycontent > div.main:visible').not('.backdrop').each(_eachSwapHide);
    }

    /* Each Function */
    function _eachSwapHide(i, el) {
        hide(el.id);
    }

    /* ----- Event: Fire Open Layout ---------------------------------------------------------------------------------- */
    function openerOnClick(e) {
        e.preventDefault();

        Navigation.setAllowCloseMenu();

        if ($(this).parent().hasClass("navplusbutton")) {
            return;
        }

        let selector = $(this).data('target');
        let id = $(this).data('id');
        let target = $(this).data('targetid');
        let icon = $(this).data('icon');
        let not_nav = $(this).attr("data-notnav");

        if (not_nav == "true") {
            let item = AppData.navigation.find(function(e) {
                return e.module == selector;
            });

            if (item) {
                id = item.id;
                target = item.target;
                icon = item.icon;
            }
        }

        let menuitem = AppData.navigation.find(function(e) {
            return e.id == id;
        });

        writeDbLog(selector, (menuitem !== undefined ? menuitem.name[localState.lang] : ""), "user_logs", "menuopen");

        localState.layoutActiveMenu = id;
        let selector_class;
        let shown_element;

        if (target) {
            if(menuitem !== undefined) {
                var header = $('#' + selector + '-details header');
                header.find('h3 .data-icon').removeAttr('class').addClass("data-icon " + icon);
                // header.find('h3 .data-label').html(menuitem.name[localState.lang]);

                shown_element = $("#"+selector+"-details");

                let finishExecution = appenToLanding(menuitem, shown_element, selector+"-details");
                if (finishExecution) {
                    console.log("finished?");
                    return;
                }
            }

            selector_class = selector.charAt(0).toUpperCase() + selector.slice(1);
            window[selector_class].showDetails(target);

            if (typeof window[selector_class].showList == "function") {
                window[selector_class].showList(target);
            }

            if (!menuitem.open_in_main) {
                Navigation.hideMenu();
            }
        } else {
            if(selector !== undefined) {
                selector = selector.toLowerCase();
                let header = $('#' + selector + ' header');

                if(menuitem !== undefined) {
                    header.find('h3 .data-icon').removeAttr('class').addClass("data-icon " + icon);
                    header.find('h3 .data-label').html(menuitem.name[localState.lang]);

                    shown_element = $("#"+selector);

                    let finishExecution = appenToLanding(menuitem, shown_element, selector);
                    if (finishExecution) {
                        console.log("no target finished?");
                        return;
                    }
                }

                selector_class = selector.charAt(0).toUpperCase() + selector.slice(1);
                if (window[selector_class] != undefined && typeof window[selector_class].showList == "function") {
                    window[selector_class].showList("");
                }

                Layout.show(selector);
            }
        }

        for (let i in clientEventListeners) {
            if (clientEventListeners[i].type == "open") {
                $(document).trigger(clientEventListeners[i].event_name);
            }
        }

        setMainSizes();
    }

    function appenToLanding(menuitem, shown_element, selector) {
        if (menuitem.open_in_main) {
            shown_element.appendTo($(".landing_elements"));
            shown_element.attr("data-width", menuitem.width_in_main);

            if($(".closemainbutton", shown_element).length == 0) {
                let closemainbutton = $("<div/>").addClass("closemainbutton color-primary open-main").attr("data-target", selector).attr("data-id", menuitem.id).attr("data-notnav", "true");
                closemainbutton.append('<i class="fas fa-times-circle"></i>');
                shown_element.append(closemainbutton);
            }

            if (menuitem.width_in_main != undefined && menuitem.width_in_main > 0) {

                let new_percent = menuitem.width_in_main;
                let shrink = 0;
                let other_visible = $("#landing .landing_elements > div").not(".hero, .main, .countdown_bc").is(":visible");
                let main_visible = $("#landing .landing_elements > div.main").not("#"+selector).is(":visible");

                if (!other_visible && !main_visible) {
                    new_percent = 100;
                    shrink = 1;
                }

                shown_element.css({
                    'flex': '0 '+shrink+' '+new_percent+"%"
                });
            }

            $('header', shown_element).hide();
        } else {
            shown_element.appendTo($(".bodycontent"));
            $('header', shown_element).show();
        }

        if (selector != "hamburger") {
            Layout.hide("hamburger");
        }

        let return_val = false;

        if (shown_element.is(':visible')) {
            $(".closemainbutton", shown_element).remove();
            Layout.hide(selector);
            $('header', shown_element).show();
            return_val = true;
        }

        return return_val;
    }

    function setMainSizes() {
        let only_with_percent = true;

        $.each($("#landing .landing_elements > div.main"), function () {
            let new_percent = $(this).attr("data-width");
            let shrink = 0;

            let other_visible = $("#landing .landing_elements > div").not(".hero, .main, #playerElementBackground, .countdown_bc").is(":visible");
            let main_visible = $("#landing .landing_elements > div.main").not(this).is(":visible");

            if (new_percent == undefined || other_visible) {
                console.log("-----------------not only with percent---------------");
                only_with_percent = false;
            }

            if (!other_visible && !main_visible) {
                new_percent = 100;
                shrink = 1;
            }

            if (new_percent != undefined) {
                $(this).css({
                    'flex': '0 '+shrink+' ' + new_percent + "%"
                });
            } else {
                $(this).css({
                    'flex': ''
                });
            }
        });

        if (only_with_percent) {
            $.each($("#landing .landing_elements > div.main"), function () {
                $(this).css({
                    'flex': '1 1 100%'
                });
            });
        }
    }

    function dashboardShow(e) {
        var dashparam = e;
        var selector = dashparam.substr(0, dashparam.indexOf('###'));
        var target = dashparam.substr(dashparam.indexOf('###') + 3);

        let elementSelector = "[data-target='"+selector+"']";
        if (target != ""){
            elementSelector += "[data-targetid='"+target+"']";
        }

        $(elementSelector).trigger("mouseup");

        //selector = selector.charAt(0).toUpperCase() + selector.slice(1);
        //window[selector].showDetails(target);
    }

    function dashboardHide(e) {
        if(e === 'weblinks-iframe') {
            Weblinks.closeWeblinkIframe();
        } else {
            Layout.hide(e);
        }
    }

    /* ----- Event: Fire Swap Layout ---------------------------------------------------------------------------------- */
    function swaperOnClick(e) {
        e.preventDefault();

        Navigation.setAllowCloseMenu();

        var selector = $(this).data('target');
        var id = $(this).data('id'),
            menuitem = AppData.navigation.find(function(e) {
                return e.id == id;
            });

        let shown_element;
        let selector_class;

        localState.layoutActiveMenu = id;

        if(selector !== "hamburger")
            writeDbLog(selector, (menuitem !== undefined ? menuitem.name[localState.lang] : ""), "user_logs", "menuopen");

        if ($(this).data('targetid')) {
            if(menuitem !== undefined) {
                $('#' + selector + '-details header').find('h3 .data-icon').removeAttr('class').addClass("data-icon " + $(this).data('icon'));

                shown_element = $("#"+selector+"-details");

                let finishExecution = appenToLanding(menuitem, shown_element, selector+"-details");
                if (finishExecution) {
                    return;
                }
            }

            selector_class = selector.charAt(0).toUpperCase() + selector.slice(1);
            window[selector_class].showDetails($(this).data('targetid'));

            if (typeof window[selector_class].showList == "function") {
                window[selector_class].showList($(this).data('targetid'));
            }
        } else {
            selector = selector.toLowerCase();

            if(menuitem !== undefined) {
                var header = $('#' + selector + ' header');
                header.find('h3 .data-icon').removeAttr('class').addClass("data-icon " + $(this).data('icon'));
                header.find('h3 .data-label').html(menuitem.name[localState.lang]);

                shown_element = $("#"+selector);

                let finishExecution = appenToLanding(menuitem, shown_element, selector);
                if (finishExecution) {
                    return;
                }
            }

            selector_class = selector.charAt(0).toUpperCase() + selector.slice(1);
            if (window[selector_class] != undefined && typeof window[selector_class].showList == "function") {
                window[selector_class].showList("");
            }

            Layout.swap(selector);
            selector = null;
        }

        for (let i in clientEventListeners) {
            if (clientEventListeners[i].type == "swap") {
                $(document).trigger(clientEventListeners[i].event_name);
            }
        }

        setMainSizes();
    }

    /* ----- Switch Header -------------------------------------------------------------------------------------------- */
    function switchHeader() {
        if (AppData.profile && 'name' in AppData.profile) {
            var
                $subTitle = $('div.main header .data-subtitle');
            $subTitle
                .removeClass('fadeIn')
                .addClass('animated fadeOut');
            $subTitle.on('animationend animationcancel', function (e) {
                $subTitle
                    .off('animationend animationcancel')
                    .html(
                        headerSwithed ? AppData.modulsettings.subTitle[localState.lang] :
                            ('userInfo' in AppData.settings && AppData.settings.userInfo === 'ip')
                                ? AppData.server.client_addr || 'NOIP' : AppData.profile.name[localState.lang]
                    )
                    .removeClass('fadeOut')
                    .addClass('fadeIn');
                headerSwithed = !headerSwithed;
                $subTitle = null;
            });
            setTimeout(switchHeader, 5000);
        }
    }

    /* ----- Event: Fire Close Layout --------------------------------------------------------------------------------- */
    function closerOnClick(e) {
        e.preventDefault();

        if(AppData.settings.homeScreen === "floating") {
            $("#" + localState.layout[localState.layout.length - 2]).show();
            $("#" + localState.layout[localState.layout.length - 2]).attr("style", "background-color: transparent");
        }

        if (AppData.settings.homeScreen === "floating") {
            $("#navplus").find(".container .open-main").css("border", '');
        } else {
            $("nav").find(".container .open-main").css("border", '');
        }
        var selector = $(this).closest('div.main').prop('id');
        Layout.hide(selector);
        localState.layoutActiveMenu = undefined;
        selector = null;
    }

    /* ----- Event: Backdrop onClick ---------------------------------------------------------------------------------- */
    function backdropOnClick(e) {
        e.preventDefault();
        Layout.hide('hamburger');
    }

    function BuildFromSource() {
        $("#software .software_inner span").stop();
        buildFooter();
    }

    function buildFooter() {

        //clearTimeout(slideTimer);
        cancelAnimationFrame(slideTimer);

        var regexp = /\[img:([0-9a-zA-Z]{8})\]/g;
        var statusline_images;
        var matches = [];
        while (statusline_images = regexp.exec(AppData.settings.statusline)) {
            matches.push(statusline_images);
        }

        var sline = AppData.settings.statusline;
        var images_loaded = 0;
        allslides = 0;

        for (var i in matches) {
            var image = AppData.medias.find(function (item) {
                return item.id == matches[i][1]
            });

            if (image && image.files[localState.lang] != undefined) {

                let file = image.files[localState.lang].file;

                allslides++;
                var img = new Image();
                img.src = file; //"upload/media/" + image.filename;
                img.onload = function() {
                    images_loaded++;
                    if (images_loaded == matches.length) {
                        if(setFooterWidth()) {
                            slideFooter();
                        }
                    }
                }

                sline = sline.replace(matches[i][0], '<img class="footer_item" src="' + file + '">');
            } else {
                sline = sline.replace(matches[i][0], '');
            }
        }

        var regexp_text = /\[text:([^\]]*)\]/g;
        var statusline_texts;
        var matches_text = [];
        while (statusline_texts = regexp_text.exec(sline)) {
            matches_text.push(statusline_texts);
        }

        for (var i in matches_text) {
            allslides++;
            sline = sline.replace(matches_text[i][0], '<div class="footer_item"><span>' + matches_text[i][1] + '</span></div>');
        }

        var version = '<div class="sline">' + sline + '</div>';

        $('#software').css({
            position: "fixed",
            top: "-1000px"
        }).appendTo($("body"));

        /* Set Header Version String */
        $('#software .software_inner').html(version);

        if (matches.length == 0) {
            setFooterWidth();
        }
    }

    function setFooterWidth() {
        if (String(AppData.settings.showVersion) === 'true') {
            var all_width = 0;
            $(".software_inner .sline .footer_item").each(function () {
                all_width += Math.ceil($(this).outerWidth());
            });
            if(all_width !== 0) {
                $(".software_inner .sline").css("width", all_width);
            }
            $("#software").css({
                position: "",
                top: ""
            }).appendTo($("#landing"));
            actslide = 0;

            if (AppData.settings.homeScreen != "classic") {
                $(".main").not(".system, #unity3d-details, #landing, #hamburger").addClass("hasfooter");

                let elem = $(".hasfooter:visible article");
                contentMargin(elem);
            }

            return all_width > $("#software .software_inner").outerWidth();
        } else {
            if (AppData.settings.homeScreen != "classic") {
                $(".main").not(".system, #unity3d-details, #landing, #hamburger").removeClass("hasfooter");

                let elem = $(".main:visible article").not(".system, #unity3d-details, #landing, #hamburger");
                contentMargin(elem);
            }
        }

        return false;
    }

    function contentMargin(elem) {
        let something_visible = $("article .container:visible", $(".main").not(".system, #unity3d-details, #landing, #hamburger"));
        if (something_visible.length > 0) {
            let main_height = elem.outerHeight();
            let visiblecont_height = something_visible.outerHeight();
            let mtop = (main_height - visiblecont_height) / 2;
            something_visible.css("margin-top", mtop + "px");
        }
    }

    function slideFooter() {
        slideTimer = requestAnimationFrame(moveFooter);
    }

    function moveFooter() {
        slideTimer = requestAnimationFrame(moveFooter);

        if (!setFooterWidth()) {
            return;
        }

        var first = $("#software .software_inner .sline .footer_item").first();

        var currTrans = $("#software .software_inner .sline").css('transform').split(/[()]/)[1];
        var posx = currTrans.split(',')[4];

        var nextpos = posx - 0.5;

        $("#software .software_inner .sline").css({
            transform: 'translateX('+nextpos+'px)'
        });

        if (-first.outerWidth() > nextpos) {
            first.appendTo($("#software .software_inner .sline"));
            $("#software .software_inner .sline").css({
                transform: 'translateX(0)'
            });
        }

    }

    function GetMediasFromSource() {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: "getMedias"
            },
            success: function (resp) {
                AppData.medias = resp;
                BuildFromSource();
            }
        });

    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(document).on('mouseup touchend', '.open-main', openerOnClick);
    $(document).on('click', '.swap-main', swaperOnClick);
    $(document).on('click', '.close-main', closerOnClick);
    $(document).on('click touchend', 'div.main.backdrop', backdropOnClick);
    $(document).on('mouseup touchend', 'img.logo', hideAll);
    $(document).on('click', '.home', hideAll);
    $(document).on('mouseup touchend', '.closebutton', hideAll);
    $(window).on('resize orientationchange', function () {
        var $bubbles = $('.bubbles1, .bubbles2');
        $('#resolution').text(screen.width + 'x' + screen.height);
        if (AppData.settings.bubble != "hidden") {
            if ($bubbles.is(':visible')) {
                $($bubbles).hide();
                requestAnimationFrame(function () {
                    $bubbles.show();
                    $bubbles = null;
                });
            }
        } else {
            $($bubbles).hide();
        }
    });
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        show: show,
        dashboardShow:dashboardShow,
        dashboardHide: dashboardHide,
        hide: hide,
        swap: swap,
        BuildFromSource: BuildFromSource,
        GetMediasFromSource: GetMediasFromSource,
        hideAll: hideAll,
        _eachInitNavigation: _eachInitNavigation,
        setMainSizes: setMainSizes
    };
})();
